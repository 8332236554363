<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-radio-group v-model="radio" class="vg_mr_8" size="mini" @input="getDataList">
          <el-radio-button label="USD"></el-radio-button>
          <el-radio-button label="RMB"></el-radio-button>
        </el-radio-group>
        <el-date-picker
          size="small"
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          value-format="timestamp"
          v-model="dateRange"
          type="year"
          placeholder="统计年份"
        />
        <el-button type="primary" size="small" class="vd_export" @click="getDataList()">查询</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right"> 刷新 </el-button>
        <el-button type="success" size="small" class="vd_export" @click="exportExcel()"> 导出Excel </el-button>
      </div>
      <DynamicUTable
        ref="partMultiTable"
        :tableData="tableData"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :page-size="50"
        :need-fixed-height="true"
        :need-search="true"
        :need-check-box="false"
        :need-all-column-summary="true"
        :showSummary="true"
        :skipSummaryIndex="[1, 2, 3]"
        @getTableData="getDataList"
      >
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';
import { keepNumber } from '@assets/js/regExUtil';
import { tableProperties } from '@/views/StatisticsManagement/SCAStatistics/scas';
import { downloadFile, getDept } from '@api/public';
import { sconAPI } from '@api/modules/scon';

export default {
  name: 'SCA Statistics',
  components: {
    DynamicUTable
  },
  data() {
    return {
      dateRange: null,
      tableProperties: cloneDeep(tableProperties),
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      radio: 'USD'
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    keepNumber,
    initData() {
      this.getDataList();
      this.changeTableProperties();
    },
    async changeTableProperties() {
      this.tableProperties.find(({ label }) => label === '销售部门').options = await getDept(['业务', '电商', '内贸'], 'default');
    },
    //获取数据
    async getDataList() {
      this.loadFlag = true;
      let searchForm = cloneDeep(this.$refs.partMultiTable.searchForm);
      searchForm.yearTime = this.dateRange;
      searchForm.cust_currency = this.radio;
      await sconAPI.getSconTotalStatistics(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.loadFlag = false;
      });
    },
    //跨分页导出excel
    exportExcel() {
      let requestBody = { type: 1, ...this.$refs.partMultiTable.searchForm, cust_currency: this.radio, yearTime: this.dateRange };
      sconAPI.exportExcel(requestBody).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: data.fileName, type: '.xlsx' });
      });
    },
    //刷新
    buttonRefresh() {
      this.$refs.partMultiTable.resetFields();
      this.dateRange = null;
      this.getDataList();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
